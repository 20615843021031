import locale6c3fbcfd from '../../locales/messages/ru.json'
import locale9ec66352 from '../../locales/messages/en.json'
import locale00be6ddc from '../../locales/messages/pt.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"ru","name":"Русский","file":"ru.json"},{"code":"en","name":"English","file":"en.json"},{"code":"pt","name":"Portuguese","file":"pt.json"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "~/locales/messages",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ru","name":"Русский","file":"ru.json"},{"code":"en","name":"English","file":"en.json"},{"code":"pt","name":"Portuguese","file":"pt.json"}],
  localeCodes: ["ru","en","pt"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "r",
  3: "u",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "o",
  17: "c",
  18: "a",
  19: "l",
  20: "e",
  21: "s",
  22: "/",
  23: "m",
  24: "e",
  25: "s",
  26: "s",
  27: "a",
  28: "g",
  29: "e",
  30: "s",
  31: "/",
  32: "r",
  33: "u",
  34: ".",
  35: "j",
  36: "s",
  37: "o",
  38: "n",
  39: "\"",
  40: ",",
  41: "\"",
  42: "e",
  43: "n",
  44: ".",
  45: "j",
  46: "s",
  47: "o",
  48: "n",
  49: "\"",
  50: ":",
  51: "\"",
  52: ".",
  53: ".",
  54: "/",
  55: "l",
  56: "o",
  57: "c",
  58: "a",
  59: "l",
  60: "e",
  61: "s",
  62: "/",
  63: "m",
  64: "e",
  65: "s",
  66: "s",
  67: "a",
  68: "g",
  69: "e",
  70: "s",
  71: "/",
  72: "e",
  73: "n",
  74: ".",
  75: "j",
  76: "s",
  77: "o",
  78: "n",
  79: "\"",
  80: ",",
  81: "\"",
  82: "p",
  83: "t",
  84: ".",
  85: "j",
  86: "s",
  87: "o",
  88: "n",
  89: "\"",
  90: ":",
  91: "\"",
  92: ".",
  93: ".",
  94: "/",
  95: "l",
  96: "o",
  97: "c",
  98: "a",
  99: "l",
  100: "e",
  101: "s",
  102: "/",
  103: "m",
  104: "e",
  105: "s",
  106: "s",
  107: "a",
  108: "g",
  109: "e",
  110: "s",
  111: "/",
  112: "p",
  113: "t",
  114: ".",
  115: "j",
  116: "s",
  117: "o",
  118: "n",
  119: "\"",
  120: "}",
}

export const localeMessages = {
  'ru.json': () => Promise.resolve(locale6c3fbcfd),
  'en.json': () => Promise.resolve(locale9ec66352),
  'pt.json': () => Promise.resolve(locale00be6ddc),
}
