import { v4 as uuidv4 } from 'uuid';
import { format, parseISO } from '@evd3v/date-fns';
import {
  addWelcomeMessage,
  addTimestamps,
  getUserSupportName
} from './support.helpers';
import { MEMBER_TYPE, MESSAGE_TYPE } from './support.const';
import SupportChatApi from './support.api';

class SupportChatService {
  constructor(app, client, hub, $config, $store, $filesService) {
    this.app = app;
    this.api = new SupportChatApi(client, $config);
    this.hub = hub;
    this.$store = $store;
    this.$filesService = $filesService;
    this.groupsMessages = [];
    this.appealIds = [];
    this.handleMessage = null;
    this.filesCount = 0;

    this.onMessageLive();
  }

  /*
   * handleMessage - Получает отформатированные сообщения и добавляет их в последнее обращение
   */
  async init(handleMessage) {
    this.appealIds = await this.getAllAppealIds();
    const groupsMessages = await this.getLastMessages();
    /*
     * Если подписка на сообщение уже есть, то больше не подписываемся
     */
    if (!this.handleMessage) this.onMessage(handleMessage);

    return {
      groupsMessages
    };
  }

  async getLastMessages() {
    const {
      currentChatId,
      currentAppealMessages,
      previousAppealMessages
    } = await this.api.getLastMessages();

    /*
     * Приветственное сообщение всегда добавляем в конце при октрытии чата
     */
    this.groupsMessages = addWelcomeMessage(
      await this.formirationGroupMessages(
        [previousAppealMessages, currentAppealMessages],
        true
      )
    );

    this.groupsMessages.chatId = currentChatId;

    return this.groupsMessages;
  }

  async getAllAppealIds() {
    const list = await this.api.getAllAppealIds();
    return list.slice(1);
  }

  async loadNextAppeal() {
    if (!this.appealIds.length) return;
    const id = this.appealIds[0];

    if (id) {
      const { data } = await this.api.getMessagesAppelaId(id);

      if (data?.data) {
        this.appealIds.splice(0, 1);
        return await this.formirationGroupMessages([data.data]);
      }
    }
  }

  /*
   * SOCKET MESSAGE
   */
  onMessage(cb) {
    this.handleMessage = true;
    this.hub.on('chat.message', async (message) => {
      if (message.params.chatAppealId) {
        const messages = this.groupsMessages;
        message = await this.formirationMessage(message, this.groupsMessages);
        if (message.incoming) messages[1].push(message);
        else if (message.messageType === MESSAGE_TYPE.TEXT)
          messages[1].splice(messages[1].length - 1, 1, message);
        else {
          messages[1].splice(messages[1].length - this.filesCount, 1, message);
          if (this.filesCount > 1) this.filesCount--;
        }
        this.groupsMessages = this.formirationNewMessages(messages);
        cb(this.groupsMessages);
      }
    });
  }

  onMessageLive() {
    this.hub.on('chat.message', ({ member }) => {
      if (member?.memberType === MEMBER_TYPE.Admin) {
        this.$store.commit('setIsNewMessageSupportChat', true);
      }
    });
  }

  getExt(file) {
    if (file.type.includes('image')) {
      return MESSAGE_TYPE.IMAGE;
    } else return MESSAGE_TYPE.FILE;
  }

  setMessageError(message) {
    message.isError = true;
    message.isSending = false;
    this.groupsMessages[1].splice(
      this.groupsMessages[1].length - 1,
      1,
      message
    );
  }

  /*
   * SEND MESSAGE
   */
  async sendMessage(message, isNew) {
    if (isNew)
      message = await this.formirationMessage(message, this.groupsMessages);
    this.groupsMessages[1].push(message);
    if (message.messageType === MESSAGE_TYPE.FILE) {
      let filenames;
      try {
        filenames = await this.$filesService.sendFiles(message.file);
      } catch (e) {
        this.setMessageError(message);
      }
      if (filenames) {
        try {
          await this.api.sendMessage({
            payload: JSON.stringify({ fileNames: filenames }),
            messageType: MESSAGE_TYPE.FILE
          });
        } catch (e) {
          console.log(e);
          this.setMessageError(message);
        }
      }
    } else {
      try {
        await this.api.sendMessage(message);
      } catch (e) {
        console.log(e);
        this.setMessageError(message);
      }
    }
  }

  /*
   * FILES
   */
  sendFiles(files) {
    this.sendMessage(
      {
        payload: JSON.stringify({
          lastModified: files[0].lastModified,
          lastModifiedDate: files[0].lastModifiedDate,
          size: files[0].size,
          name: files[0].name,
          type: files[0].type,
          url: URL.createObjectURL(files[0])
        }),
        file: files,
        messageType: MESSAGE_TYPE.FILE,
        member: { memberType: 'Patient' },
        createdAt: new Date().toISOString(),
        isError: false,
        isSending: true,
        id: uuidv4(),
        isLocalFile: true
      },
      false
    );
  }

  async getFileParams(file) {
    let fileInformation = JSON.parse(file.payload).filesInformation;
    if (fileInformation?.length) {
      fileInformation = fileInformation[0];
      try {
        const res = await this.$filesService.getFileByFilename(fileInformation);
        if (file.isLocalFile) {
          return {
            messageType: fileInformation.type.includes('image')
              ? MESSAGE_TYPE.IMAGE
              : MESSAGE_TYPE.FILE,
            ...res
          };
        } else {
          return {
            messageType: fileInformation.mimeType.includes('image')
              ? MESSAGE_TYPE.IMAGE
              : MESSAGE_TYPE.FILE,
            ...res
          };
        }
      } catch (e) {
        console.log(e);
      }
    }

    return null;
  }

  async formirationGroupMessages(messages) {
    const formattedGroups = await Promise.all(
      messages.map(async (group) => {
        const formattedMessages = await Promise.all(
          group
            .map(async (message) => {
              return await this.formirationMessage(message);
            })
            .map(async (message, index, list) => {
              /*
               * Определение последнего сообщения пользователя(Оператор|Пользователь)
               */
              let isLast = false;
              message = await message;
              const nextMessage = await list[index + 1];

              if (
                message?.incoming === nextMessage?.incoming &&
                nextMessage?.messageType !== MESSAGE_TYPE.SYSTEM
              )
                isLast = true;
              return {
                ...message,
                isLast
              };
            })
        );

        return addTimestamps(
          formattedMessages.map((message, index, list) => {
            /*
             * Определение последнего сообщения пользователя(Оператор|Пользователь)
             */
            let isLast = false;
            const nextMessage = list[index + 1];

            if (
              message?.incoming === nextMessage?.incoming &&
              nextMessage?.messageType !== MESSAGE_TYPE.SYSTEM
            )
              isLast = true;
            return {
              ...message,
              isLast
            };
          })
        );
      })
    );

    return formattedGroups;
  }

  async formirationMessage(message) {
    const messageType = !message.member
      ? MESSAGE_TYPE.SYSTEM
      : message.messageType;

    let params = {};

    switch (messageType) {
      case MESSAGE_TYPE.FILE:
        params = await this.getFileParams(message);
        break;
    }

    const incoming = MEMBER_TYPE[message?.member?.memberType] === 'Admin';
    return {
      id: message.id,
      time: format(parseISO(message.createdAt), 'HH:mm'),
      name: getUserSupportName(message),
      avatar: message.member?.personData?.avatarUrl,
      createdAt: message.createdAt,
      incoming,
      messageType,
      isPreview: !!incoming,
      isSending: message.isSending,
      wasRead: message.wasRead,
      isError: message.isError,
      text: message.text,
      ...params
    };
  }

  formirationNewMessages(messages) {
    const currentAppeal = messages[1];
    const prevMessage = currentAppeal[currentAppeal.length - 2];
    const lastMessage = currentAppeal[currentAppeal.length - 1];

    if (prevMessage.incoming === lastMessage.incoming && !prevMessage.isLast) {
      prevMessage.isLast = true;
      prevMessage.text = `${prevMessage.text} `;
      lastMessage.isLast = false;
    } else {
      lastMessage.isLast = false;
    }

    messages[1][currentAppeal.length - 2] = prevMessage;
    messages[1][currentAppeal.length - 1] = lastMessage;

    return messages;
  }
}

export default SupportChatService;
