import MedicalQuestionnaireApi from './medical-questionnaire.api';
import {
  BLOOD_TYPE,
  RH_FACTOR,
  FIELDS,
  BLOCKS_FIELDS_VIEW,
  MENSTRUAL_CYCLE_REGULARITY,
  ABORTION_REASON,
  PREGNANCY_RESULT,
  LIST_RADIO_BOOLEAN
} from './medical-questionnaire.const';
import {
  getCharacteristics,
  getListFromObject
} from './medical-questionnaire.helpers';
import {
  getProfileFullName,
  getNameInitial,
  getAgeTitle
} from '@/helpers/patient';

class MedicalQuestionnaireService {
  constructor(client, $config) {
    this.api = new MedicalQuestionnaireApi(client, $config);
    this.idAnketa = null;
    this.isMan = true;
  }

  async getAnketaEdit(id) {
    const { anketa } = await this.api.getMedicalQuestionnaire(id);
    const woman = anketa.medicalQuestionnaireForWomenResponse ?? {};
    this.idAnketa = anketa.id;

    /*
     * type: input - Обычный input
     * type: radio - Список чекбоксов
     * type: radio-with-input - Поле input, заполнение которого зависит от выбранно значения в поле isValueVisible
     * type: radio-with-radio - 2 списка чекбоксов, заполнение второго зависит от значения первого чекбокса
     * isOnlyRead - Поле предназначается только для чтения. Заполнять нельзя, не сохраняется на бэке
     * isBoolean - Если значение true/false. При сохранении не обрабатывает и отправляется либо выбранное значение, либо null
     * mainField - В поле записывается ключ поля, которое является основным, если оно false или пустое, то то поле так же делаем пустым
     */
    const anketaForm = [
      {
        title: 'pages.account.anketa.imt',
        fields: [
          {
            type: 'input',
            field: 'height',
            label: FIELDS.height.title,
            placeholder: FIELDS.height.placeholder,
            value: anketa.height,
            typeField: 'number',
            minValue: 1
          },
          {
            type: 'input',
            field: 'weight',
            label: FIELDS.weight.title,
            placeholder: FIELDS.weight.placeholder,
            value: anketa.weight,
            typeField: 'number',
            minValue: 1
          },
          {
            type: 'input',
            field: 'imt',
            label: 'pages.account.anketa.body-mass-index',
            placeholder: '-',
            value: anketa.bodyMassIndex || 0,
            typeField: 'number',
            disabled: true,
            isOnlyRead: true
          }
        ]
      },
      {
        title: 'pages.account.anketa.blood-allergies',
        fields: [
          {
            type: 'radio',
            field: 'bloodType',
            title: FIELDS.bloodType.title,
            list: getListFromObject(BLOOD_TYPE),
            value: anketa.bloodType
          },
          {
            type: 'radio',
            field: 'rhFactor',
            title: FIELDS.rhFactor.title,
            list: getListFromObject(RH_FACTOR),
            value: anketa.rhFactor
          },
          {
            type: 'radio-with-input',
            field: 'allergies',
            title: FIELDS.allergies.title,
            list: LIST_RADIO_BOOLEAN,
            value: !!anketa.allergies,
            isValueVisible: [true],
            input: {
              value: anketa.allergies,
              typeField: 'text',
              field: 'allergies',
              label: 'pages.account.anketa.for-what-title',
              placeholder: 'common.general-words.write-separated-commas'
            }
          }
        ]
      },
      {
        title: 'pages.account.anketa.diseases-operations',
        fields: [
          {
            type: 'radio-with-input',
            field: 'chronicDiseases',
            title: FIELDS.chronicDiseases.title,
            list: LIST_RADIO_BOOLEAN,
            value: !!anketa.chronicDiseases,
            isValueVisible: [true],
            input: {
              value: anketa.chronicDiseases,
              typeField: 'text',
              field: 'chronicDiseases',
              label: 'common.general-words.which',
              placeholder: 'common.general-words.write-separated-commas'
            }
          },
          {
            type: 'radio-with-input',
            field: 'permanentMedications',
            title: FIELDS.permanentMedications.title,
            list: LIST_RADIO_BOOLEAN,
            value: !!anketa.permanentMedications,
            isValueVisible: [true],
            input: {
              value: anketa.permanentMedications,
              typeField: 'text',
              field: 'permanentMedications',
              label: 'common.general-words.which',
              placeholder: 'common.general-words.write-separated-commas'
            }
          },
          {
            type: 'radio-with-input',
            field: 'surgeries',
            title: FIELDS.surgeries.title,
            list: LIST_RADIO_BOOLEAN,
            value: !!anketa.surgeries,
            isValueVisible: [true],
            input: {
              value: anketa.surgeries,
              typeField: 'text',
              field: 'surgeries',
              label: 'common.general-words.which',
              placeholder: 'common.general-words.write-separated-commas'
            }
          }
        ]
      }
    ];

    if (!this.isMan) {
      anketaForm.push({
        title: 'pages.account.anketa.women-health',
        fields: [
          {
            type: 'radio',
            isWoman: true,
            field: 'menstrualCycleRegularity',
            title: FIELDS.menstrualCycleRegularity.title,
            list: getListFromObject(MENSTRUAL_CYCLE_REGULARITY),
            value: woman.menstrualCycleRegularity || null
          },
          {
            type: 'input',
            isWoman: true,
            field: 'menstrualCycleDuration',
            label: FIELDS.menstrualCycleDuration.title,
            placeholder: FIELDS.menstrualCycleDuration.placeholder,
            value: woman.menstrualCycleDuration || 0,
            typeField: 'number',
            minValue: 1
          },
          // PREGNANCY
          {
            type: 'radio-with-input',
            isWoman: true,
            isBoolean: true,
            field: 'isPregnant',
            title: FIELDS.isPregnant.title,
            list: LIST_RADIO_BOOLEAN,
            value: !!woman.currentPregnancyInWeeks || null,
            isValueVisible: [true],
            mainField: 'isPregnant',
            input: {
              value: woman.currentPregnancyInWeeks || null,
              typeField: 'number',
              field: 'currentPregnancyInWeeks',
              label: FIELDS.currentPregnancyInWeeks.title,
              placeholder: 'pages.account.anketa.write-number-weeks',
              minValue: 1
            }
          },
          {
            // Если поле isPregnant будет заполнено, то отображем этот блок
            visible: 'isPregnant',
            type: 'radio-with-input',
            isWoman: true,
            field: 'pregnancyResult',
            title: FIELDS.pregnancyResult.title,
            list: getListFromObject(PREGNANCY_RESULT),
            value: woman.pregnancyResult || null,
            isValueVisible: [
              'ChildBirth',
              'PlannedAbortion',
              'UnplannedAbortion'
            ],
            mainField: 'isPregnant',
            input: {
              value: woman.pregnancyResultWeek || null,
              typeField: 'number',
              field: 'pregnancyResultWeek',
              label: FIELDS.pregnancyResultWeek.title,
              placeholder: 'pages.account.anketa.write-number-weeks'
            }
          },
          // ABORT
          {
            isWoman: true,
            type: 'radio',
            isBoolean: true,
            field: 'hadPregnanciesInThePast',
            title: FIELDS.hadPregnanciesInThePast.title,
            list: LIST_RADIO_BOOLEAN,
            value: woman.hadPregnanciesInThePast
          },
          {
            visible: 'hadPregnanciesInThePast',
            isWoman: true,
            type: 'radio-with-radio',
            isBoolean: true,
            field: 'hadAbortions',
            title: FIELDS.hadAbortions.title,
            list: LIST_RADIO_BOOLEAN,
            value: woman.hadAbortions || null,
            isValueVisible: [true],
            mainField: 'hadPregnanciesInThePast',
            radio: {
              field: 'lastAbortionReason',
              title: FIELDS.lastAbortionReason.title,
              list: getListFromObject(ABORTION_REASON),
              value: woman.lastAbortionReason || null
            }
          }
        ]
      });
    }

    return anketaForm;
  }

  async saveAnketa({ patientId, list }) {
    const womanField = 'medicalQuestionnaireForWomenRequest';
    const params = {
      patientId,
      medicalQuestionnaireForWomenRequest: {}
    };

    list.forEach((section) => {
      section.fields
        .filter((i) => !i.isOnlyRead)
        .forEach((data) => {
          const { isWoman, field, value, input, radio, type, mainField } = data;
          if (isWoman) {
            params[womanField][field] =
              typeof value !== 'boolean' ? value || null : value;
            if (input?.field) params[womanField][input.field] = input?.value;
            if (radio?.field) params[womanField][radio.field] = radio?.value;

            if (input && !value) params[womanField][input.field] = null;

            if (mainField && !params[womanField][mainField]) {
              params[womanField][field] = null;
              if (input?.field) params[womanField][input.field] = null;
              if (radio?.field) params[womanField][radio.field] = null;
            }

            // Исключение
            if (field === 'pregnancyResult') {
              if (params[womanField].pregnancyResult === 'InProgress') {
                params[womanField][field] = null;
                params[womanField][input.field] = null;
              }
            }

            if (field === 'isPregnant' && !value) {
              params[womanField].isPregnant = null;
              params[womanField].currentPregnancyInWeeks = null;
            }

            if (field === 'pregnancyResult' && !params[womanField].isPregnant) {
              params[womanField].pregnancyResult = null;
              params[womanField].pregnancyResultWeek = null;
            }
          } else if (type === 'radio-with-input') {
            params[field] = value ? input?.value : null;
          } else {
            params[field] = value ? input?.value || value : null;
            if (radio?.value) params[radio.field] = radio?.value;
          }
        });
    });

    /*
     * Если пользователь не женщина, то необходимо удалить вопросы для женщин
     */
    if (this.isMan) delete params.medicalQuestionnaireForWomenRequest;
    // else params[womanField].pregnancyResult = pregnancyResult;

    if (this.idAnketa) await this.api.updateMedicalQuestionnaire(params);
    else await this.api.addMedicalQuestionnaire(params);
  }

  async getAnketaView(id) {
    const { anketa, patient } = await this.api.getMedicalQuestionnaire(id);
    this.idAnketa = anketa.id;
    const filterAnketa = this.filterAnketaView(anketa);
    return {
      listFields: this.getAnketaFieldsView(patient, filterAnketa),
      patient: this.getPatient(patient, filterAnketa)
    };
  }

  getAnketaFieldsView({ sex }, anketa) {
    const women = anketa?.medicalQuestionnaireForWomenResponse || {};
    const result = BLOCKS_FIELDS_VIEW.map((block) => {
      return block
        .map(({ field, isWoman = false, constValue }) => {
          /*
           * Исключаем поля преднозначенные для женщин
           */
          if (sex && isWoman) return false;
          let value = field in women ? women[field] : anketa[field];
          if (constValue) value = constValue[value];

          if (typeof value === 'boolean')
            value = value ? 'common.yes-or-no.yes' : 'common.yes-or-no.no';

          return {
            id: field,
            isWoman,
            label: FIELDS[field].title,
            value: value || '-',
            isHidden: isWoman
              ? women[field] === undefined
              : anketa[field] === undefined
          };
        })
        .filter((i) => i && !i.isHidden);
    });
    return result;
  }

  // Убираем не заполненные поля + убрираем связанные, если родитель не заполнен
  filterAnketaView(anketa) {
    const women = anketa.medicalQuestionnaireForWomenResponse;

    // Анкеты еще нет, выпиливаем поля
    if (!anketa.id) {
      const fields = [
        'currentPregnancyInWeeks',
        'pregnancyResult',
        'pregnancyResultWeek',
        'hadAbortions',
        'lastAbortionReason'
      ];

      if (women) {
        for (const field of fields) {
          delete women[field];
        }
      }
    } else if (women) {
      if (!women.isPregnant) {
        delete women.isPregnant;
        delete women.currentPregnancyInWeeks;
        delete women.pregnancyResult;
        delete women.pregnancyResultWeek;
      }

      if (!women.hadAbortions) {
        delete women.hadAbortions;
        delete women.lastAbortionReason;
      }
    }
    return anketa;
  }

  getPatient(patient, anketa) {
    /*
     * Пол пациента используется для формирвоания данных при сохранении анкеты
     */
    this.isMan = patient.sex;
    const { bloodType, rhFactor } = anketa;
    return {
      ...patient,
      fullName: getProfileFullName(patient),
      initial: getNameInitial(patient, true),
      sexTitle: patient.sex ? 'Мужчина' : 'Женщина',
      age: getAgeTitle(patient),
      characteristics: getCharacteristics(anketa),
      blood: [BLOOD_TYPE[bloodType], RH_FACTOR[rhFactor]].filter((i) => i)
    };
  }
}

export default MedicalQuestionnaireService;
