export const getCharacteristics = ({ height = 0, weight = 0 }) => {
  return [
    { label: 'common.general-words.height-sm', value: height || '-' },
    { label: 'common.general-words.weight-kg', value: weight || '-' },
    { label: 'common.general-words.bmi', value: getImt(height, weight, true) }
  ];
};

export const getImt = (height = 0, weight = 0, isReplace = false) => {
  const heightImt = height / 100;
  const imt = (weight / (heightImt * heightImt)).toFixed(2);
  const res = isNaN(imt) ? '-' : `${imt}`;

  if (isReplace) {
    return res.replace('.', ',');
  }

  return res;
};

export const getListFromObject = (object) => {
  return Object.entries(object).map(([id, title]) => ({
    id,
    title
  }));
};
