import { STATUS } from './referral-consultations.const';

export const getBreadcrumbs = (status, title) => {
  const tabs = [{ title: 'Главная', url: '/' }];

  switch (status) {
    case STATUS.NEW:
      tabs.push({
        title: 'breadcrumbs.events.future-events',
        url: '/events/future'
      });
      break;
    case STATUS.ACCEPTED:
      tabs.push({
        title: 'breadcrumbs.events.current-events',
        url: '/events/current'
      });
      break;
    case STATUS.PROVIDED:
      tabs.push({
        title: 'breadcrumbs.events.closed-events',
        url: '/events/closed'
      });
      break;
    case STATUS.OVERDUE:
      tabs.push({
        title: 'breadcrumbs.events.closed-events',
        url: '/events/closed'
      });
      break;
    case STATUS.DECLINED:
      tabs.push({
        title: 'breadcrumbs.events.closed-events',
        url: '/events/closed'
      });
      break;
  }

  tabs.push({ title, url: '#' });
  return tabs;
};
