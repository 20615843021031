const getDefaultState = () => {
  return {
    /** User profiles */
    currentProfile: null,
    settings: null,
    city: null
  };
};

export const state = getDefaultState;

export const actions = {
  getUserProfiles({ commit, dispatch }) {
    const idUser = this.$auth.user.id;
    dispatch('setProfile', idUser);
  },
  setProfile({ state, commit, dispatch, rootState }, idUser) {
    const user = rootState.modules.patient.familyProfiles.find(
      (user) => user.id === idUser
    );
    commit('setCurrentProfile', user);
    this.$amplitude?.setUserId(user.id);

    if (this.$router.currentRoute.name === 'account-patients') {
      this.$router.push({ path: '/account' });
    }
  },
  async switchUserProfile({ state, commit, dispatch }, idUser) {
    const { token, refreshToken } = await this.$coreApi.getFamilyProfileToken(
      idUser
    );
    await this.$auth.setUserToken(token, refreshToken);
    window.location.reload();
  },
  getProfile({ rootState }) {
    const profile = rootState.modules.patient.profile;
    this.$auth.setUser(profile);
  },
  async getSetting({ commit }) {
    const settings = await this.$patientService.getSetting();
    commit('setSettings', settings);
  },
  async deleteProfile({ state, commit, dispatch }) {
    await this.$coreApi.deleteProfile(state.currentProfile?.id);
  },
  getCity({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Геолокация не поддерживается'));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const city = {
              longitude,
              latitude
            };

            commit('setCity', city);
          },
          (error) => {
            reject(error);
          },
          {
            enableHighAccuracy: true
          }
        );
      }
    });
  }
};

export const mutations = {
  setSettings(state, date) {
    state.settings = date;
  },
  setUserProfiles(state, profiles) {
    state.userProfiles = profiles;
  },
  setCurrentProfile(state, profile) {
    state.currentProfile = profile;
  },
  setAvatarUser(state, url) {
    this.state.auth.user.AvatarUrl = url;
  },
  setCity(state, city) {
    state.city = city;
  }
};
