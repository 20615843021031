import {
  format,
  differenceInYears,
  isTomorrow,
  isToday,
  startOfDay,
  formatRelative
} from '@evd3v/date-fns';
import { shortcutDays } from '@/consts';

let groupBy = null;

export const convertBlobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};
export const removeDuplicates = (data, key) => {
  return [...new Map(data.map((item) => [key(item), item])).values()];
};

export const getProfileFullName = (profile) => {
  if (!profile) {
    return null;
  }

  const { lastName, firstName, middleName } = profile;

  return [lastName, firstName, middleName].filter((v) => v).join(' ');
};

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].includes('base64')
    ? atob(splitDataURI[1])
    : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const openFile = (file) => {
  const url = window.URL.createObjectURL(file.blob);
  window.open(url);
};

export const generateTimestampFilename = (prefix) => {
  const currentDate = new Date();

  return `${prefix}-${format(currentDate, 'dd-MM-yyyy_HH-mm')}`;
};

export const downloadFile = (file, name) => {
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(file);

  const fileName = generateTimestampFilename(name ?? 'file');

  link.download = fileName;

  link.style.display = 'none';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);

  setTimeout(() => {
    window.URL.revokeObjectURL(link.href);
  }, 100);
};

export const birthdayToISO = (date, separator = '-') => {
  const parts = date.split(separator);
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);
  const birthday = new Date(`${month}/${day}/${year}`);
  return new Date(birthday.getTime() - birthday.getTimezoneOffset() * 60000);
};

export const checkisAdult = (date) => {
  const age = differenceInYears(new Date(), new Date(date));

  return age >= 18;
};

export const getTimeZone = () => {
  return -new Date().getTimezoneOffset() / 60;
};

export const newFormatScheduleSlots = async (slots, isSorted = true) => {
  if (!groupBy) {
    groupBy = await import('lodash.groupby');
  }

  const timestamps = slots.map((slot) => slot * 1000);
  const groupDateSlots = groupBy.default(timestamps, (timestamp) => {
    return new Date(timestamp).getMonth();
  });

  for (const date in groupDateSlots) {
    const groupSlots = groupBy.default(groupDateSlots[date], (timestamp) => {
      return new Date(timestamp).setHours(0, 0, 0, 0);
    });

    groupDateSlots[date] = Object.values(groupSlots).map((item) => {
      const date = new Date(item[0]);

      let label;
      if (isToday(date) || isTomorrow(date)) {
        label = formatRelative(date, new Date()).split(' ')[0];
      } else {
        label = format(date, 'dd MMM');
      }

      return {
        label,
        dateRaw: startOfDay(date),
        dateIso: date.toISOString(),
        day: date.getDate(),
        month: date.getMonth(),
        // shortDay: this.$t(shortcutDays)[date.getDay()],
        shortDay: shortcutDays[date.getDay()],
        slots: [...item]
      };
    });
  }

  return groupDateSlots;
};

export const sortObjectByKeys = (obj) => {
  const sortedKeys = Object.keys(obj).sort((a, b) => parseInt(b) - parseInt(a));
  const sortedObj = [];

  sortedKeys.forEach((key) => {
    sortedObj[`!${key}`] = obj[key];
  });

  return sortedObj;
};

export const closestSlotFilter = (timestamp) => {
  const date = new Date(timestamp * 1000);

  let label;
  if (isToday(date) || isTomorrow(date)) {
    label = formatRelative(date, new Date()).split(' ')[0];
  } else {
    label = format(new Date(date), `d MMMM`);
    const dateSuffix = localStorage.locale === 'ru' ? '' : 'th of';
    const datePreffix = localStorage.locale === 'ru' ? '' : 'on';
    if (dateSuffix) {
      label = `${datePreffix} ${label.split(' ')[0]}${dateSuffix} ${
        label.split(' ')[1]
      }`;
    }
  }

  return label;
};

export const getAge = (date) => {
  if (!date) {
    return 0;
  }
  let birthDate = date.split('T')[0];
  birthDate = Math.floor(
    (new Date() - new Date(birthDate).getTime()) / 3.15576e10
  );

  return `${birthDate} лет`;
};

export const validDate = (value) => {
  // Parse the date parts to integers
  const parts = value.split('-');
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);

  // Check is greater than today
  const today = new Date().setHours(0, 0, 0, 0);
  const date = new Date(`${month}/${day}/${year}`).setHours(0, 0, 0, 0);

  if (date > today) {
    return false;
  }

  // Check the ranges of month and year
  if (
    year < 1850 ||
    year > new Date().getFullYear() ||
    month === 0 ||
    month > 12
  ) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

export const getNameInitial = (user) => {
  const { FirstName, LastName, MiddleName } = user;
  const initalFirst = FirstName ? FirstName.slice(0, 1) : '';
  const initalMiddle = MiddleName ? MiddleName.slice(0, 1) : '';

  return `${LastName} ${initalFirst}.${initalMiddle}.`;
};

export const filterEmptyParameters = (params) => {
  const filtered = Object.entries(params)
    .filter((param) => {
      const value = param[1];
      if (Array.isArray(value) && !value.length) return false;
      return value !== undefined && value !== null;
    })
    .map((entry) => ({ [entry[0]]: entry[1] }));

  return Object.assign({}, ...filtered);
};
export const formationDate = (value, format) => {
  const date = new Date(value);

  let options = {};

  if (format.includes('second')) {
    options = { ...options, minute: '2-digit', second: '2-digit' };
  }

  if (format.includes('time')) {
    options = { ...options, hour: '2-digit', minute: '2-digit' };
  }

  if (format.includes('date')) {
    options = { ...options, day: '2-digit', month: '2-digit', year: 'numeric' };
  }

  if (format.includes('monthlong')) {
    options = { ...options, month: 'long' };
  }

  return new Intl.DateTimeFormat('ru-Ru', options).format(date);
};

export const getFullYear = (value) => {
  return new Date(value).getFullYear();
};

export const declOfNum = (number, words) => {
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ];
};

export const deepEqual = (x, y) => {
  const ok = Object.keys;
  const tx = typeof x;
  const ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
};

export const numberFormat = (value) => {
  return new Intl.NumberFormat(value).format(value);
};

export const chunkArray = (array, chunkSize) => {
  return array.reduce((all, one, i) => {
    const ch = Math.floor(i / chunkSize);
    all[ch] = [].concat(all[ch] || [], one);
    return all;
  }, []);
};

export const сurrencyFormat = (price, options) => {
  if (process.client) {
    const locale = window.$nuxt.$config.APP_LOCALE || 'ru';
    const currency = {
      en: 'USD',
      ru: 'RUB'
    };

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency[locale],
      minimumFractionDigits: 0,
      ...options
    }).format(price);
  }
};

export const localeFormatDate = (value) => {
  return {
    ru: format(value, 'dd.MM.yyyy'),
    en: format(value, 'MMM dd, yyyy')
  };
};

export const isArraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};
