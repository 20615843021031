import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _cbd4fa14 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _36c6cec9 = () => interopDefault(import('../pages/analysis/index.vue' /* webpackChunkName: "pages/analysis/index" */))
const _4f8517d2 = () => interopDefault(import('../pages/analysis/index/categories.vue' /* webpackChunkName: "pages/analysis/index/categories" */))
const _1b456af0 = () => interopDefault(import('../pages/analysis/index/populars.vue' /* webpackChunkName: "pages/analysis/index/populars" */))
const _736366cc = () => interopDefault(import('../pages/analysis/index/service/_id.vue' /* webpackChunkName: "pages/analysis/index/service/_id" */))
const _2dd79d02 = () => interopDefault(import('../pages/analysis/index/tag/_id.vue' /* webpackChunkName: "pages/analysis/index/tag/_id" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _04b996e8 = () => interopDefault(import('../pages/dashboard/index/insurance.vue' /* webpackChunkName: "pages/dashboard/index/insurance" */))
const _749e08aa = () => interopDefault(import('../pages/digital-portal.vue' /* webpackChunkName: "pages/digital-portal" */))
const _7b0e2f3d = () => interopDefault(import('../pages/doctors/index.vue' /* webpackChunkName: "pages/doctors/index" */))
const _b9922dfa = () => interopDefault(import('../pages/events.vue' /* webpackChunkName: "pages/events" */))
const _5eb79aa8 = () => interopDefault(import('../pages/events/closed.vue' /* webpackChunkName: "pages/events/closed" */))
const _7887036d = () => interopDefault(import('../pages/events/current.vue' /* webpackChunkName: "pages/events/current" */))
const _0d297902 = () => interopDefault(import('../pages/events/future.vue' /* webpackChunkName: "pages/events/future" */))
const _7e84fd2e = () => interopDefault(import('../pages/events/_id.vue' /* webpackChunkName: "pages/events/_id" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _ec08963c = () => interopDefault(import('../pages/moms-school/index.vue' /* webpackChunkName: "pages/moms-school/index" */))
const _4b434144 = () => interopDefault(import('../pages/ochnoe-poseschenie-vracha/index.vue' /* webpackChunkName: "pages/ochnoe-poseschenie-vracha/index" */))
const _0f685552 = () => interopDefault(import('../pages/social.vue' /* webpackChunkName: "pages/social" */))
const _4bab454e = () => interopDefault(import('../pages/telemed-centers.vue' /* webpackChunkName: "pages/telemed-centers" */))
const _41a08696 = () => interopDefault(import('../pages/account/agreements.vue' /* webpackChunkName: "pages/account/agreements" */))
const _5c2e6701 = () => interopDefault(import('../pages/account/insurance/index.vue' /* webpackChunkName: "pages/account/insurance/index" */))
const _34acf7f7 = () => interopDefault(import('../pages/account/notification.vue' /* webpackChunkName: "pages/account/notification" */))
const _ad1494cc = () => interopDefault(import('../pages/account/patients.vue' /* webpackChunkName: "pages/account/patients" */))
const _07382da2 = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _5fd9a362 = () => interopDefault(import('../pages/analysis/checkout/index.vue' /* webpackChunkName: "pages/analysis/checkout/index" */))
const _0d67a3f2 = () => interopDefault(import('../pages/app-deletion-info/android.vue' /* webpackChunkName: "pages/app-deletion-info/android" */))
const _80e47718 = () => interopDefault(import('../pages/monitoring/emergency.vue' /* webpackChunkName: "pages/monitoring/emergency" */))
const _4bb8c615 = () => interopDefault(import('../pages/monitoring/remote-monitoring/index.vue' /* webpackChunkName: "pages/monitoring/remote-monitoring/index" */))
const _08991042 = () => interopDefault(import('../pages/policy-survey/cancer-check.vue' /* webpackChunkName: "pages/policy-survey/cancer-check" */))
const _7e2d1c2e = () => interopDefault(import('../pages/policy-survey/my-health.vue' /* webpackChunkName: "pages/policy-survey/my-health" */))
const _2c4641e0 = () => interopDefault(import('../pages/promo/consultations.vue' /* webpackChunkName: "pages/promo/consultations" */))
const _6f211a07 = () => interopDefault(import('../pages/vtb/debit-card-instruction.vue' /* webpackChunkName: "pages/vtb/debit-card-instruction" */))
const _70ef3994 = () => interopDefault(import('../pages/vtb/insurance-instruction.vue' /* webpackChunkName: "pages/vtb/insurance-instruction" */))
const _6e17eb93 = () => interopDefault(import('../pages/account/insurance/add/index.vue' /* webpackChunkName: "pages/account/insurance/add/index" */))
const _2cc55b12 = () => interopDefault(import('../pages/account/insurance/add/mama.vue' /* webpackChunkName: "pages/account/insurance/add/mama" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _96cead52 = () => interopDefault(import('../pages/account/patient/_id/index.vue' /* webpackChunkName: "pages/account/patient/_id/index" */))
const _72dd164a = () => interopDefault(import('../pages/analysis/checkout/_id.vue' /* webpackChunkName: "pages/analysis/checkout/_id" */))
const _3bc4fe74 = () => interopDefault(import('../pages/event/analysis/_id.vue' /* webpackChunkName: "pages/event/analysis/_id" */))
const _e5096c86 = () => interopDefault(import('../pages/monitoring/remote-monitoring/_id.vue' /* webpackChunkName: "pages/monitoring/remote-monitoring/_id" */))
const _00ec373f = () => interopDefault(import('../pages/referral/analysis/_id.vue' /* webpackChunkName: "pages/referral/analysis/_id" */))
const _c3369da6 = () => interopDefault(import('../pages/referral/consultations/_id.vue' /* webpackChunkName: "pages/referral/consultations/_id" */))
const _0f33ca3f = () => interopDefault(import('../pages/account/patient/_id/anketa.vue' /* webpackChunkName: "pages/account/patient/_id/anketa" */))
const _79578df5 = () => interopDefault(import('../pages/doctors/_slug.vue' /* webpackChunkName: "pages/doctors/_slug" */))
const _52bef010 = () => interopDefault(import('../pages/medicine-directions/_id.vue' /* webpackChunkName: "pages/medicine-directions/_id" */))
const _595587ca = () => interopDefault(import('../pages/moms-school/_id.vue' /* webpackChunkName: "pages/moms-school/_id" */))
const _4f332f46 = () => interopDefault(import('../pages/monitoring/_id.vue' /* webpackChunkName: "pages/monitoring/_id" */))
const _0873b942 = () => interopDefault(import('../pages/queue/_id.vue' /* webpackChunkName: "pages/queue/_id" */))
const _68542409 = () => interopDefault(import('../pages/specializations/_slug.vue' /* webpackChunkName: "pages/specializations/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0e433b93,
    name: "about"
  }, {
    path: "/account",
    component: _cbd4fa14,
    name: "account"
  }, {
    path: "/analysis",
    component: _36c6cec9,
    name: "analysis",
    children: [{
      path: "categories",
      component: _4f8517d2,
      name: "analysis-index-categories"
    }, {
      path: "populars",
      component: _1b456af0,
      name: "analysis-index-populars"
    }, {
      path: "service/:id?",
      component: _736366cc,
      name: "analysis-index-service-id"
    }, {
      path: "tag/:id?",
      component: _2dd79d02,
      name: "analysis-index-tag-id"
    }]
  }, {
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard",
    children: [{
      path: "insurance",
      component: _04b996e8,
      name: "dashboard-index-insurance"
    }]
  }, {
    path: "/digital-portal",
    component: _749e08aa,
    name: "digital-portal"
  }, {
    path: "/doctors",
    component: _7b0e2f3d,
    name: "doctors"
  }, {
    path: "/events",
    component: _b9922dfa,
    name: "events",
    children: [{
      path: "closed",
      component: _5eb79aa8,
      name: "events-closed"
    }, {
      path: "current",
      component: _7887036d,
      name: "events-current"
    }, {
      path: "future",
      component: _0d297902,
      name: "events-future"
    }, {
      path: ":id?",
      component: _7e84fd2e,
      name: "events-id"
    }]
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq"
  }, {
    path: "/moms-school",
    component: _ec08963c,
    name: "moms-school"
  }, {
    path: "/ochnoe-poseschenie-vracha",
    component: _4b434144,
    name: "ochnoe-poseschenie-vracha"
  }, {
    path: "/social",
    component: _0f685552,
    name: "social"
  }, {
    path: "/telemed-centers",
    component: _4bab454e,
    name: "telemed-centers"
  }, {
    path: "/account/agreements",
    component: _41a08696,
    name: "account-agreements"
  }, {
    path: "/account/insurance",
    component: _5c2e6701,
    name: "account-insurance"
  }, {
    path: "/account/notification",
    component: _34acf7f7,
    name: "account-notification"
  }, {
    path: "/account/patients",
    component: _ad1494cc,
    name: "account-patients"
  }, {
    path: "/account/settings",
    component: _07382da2,
    name: "account-settings"
  }, {
    path: "/analysis/checkout",
    component: _5fd9a362,
    name: "analysis-checkout"
  }, {
    path: "/app-deletion-info/android",
    component: _0d67a3f2,
    name: "app-deletion-info-android"
  }, {
    path: "/monitoring/emergency",
    component: _80e47718,
    name: "monitoring-emergency"
  }, {
    path: "/monitoring/remote-monitoring",
    component: _4bb8c615,
    name: "monitoring-remote-monitoring"
  }, {
    path: "/policy-survey/cancer-check",
    component: _08991042,
    name: "policy-survey-cancer-check"
  }, {
    path: "/policy-survey/my-health",
    component: _7e2d1c2e,
    name: "policy-survey-my-health"
  }, {
    path: "/promo/consultations",
    component: _2c4641e0,
    name: "promo-consultations"
  }, {
    path: "/vtb/debit-card-instruction",
    component: _6f211a07,
    name: "vtb-debit-card-instruction"
  }, {
    path: "/vtb/insurance-instruction",
    component: _70ef3994,
    name: "vtb-insurance-instruction"
  }, {
    path: "/account/insurance/add",
    component: _6e17eb93,
    name: "account-insurance-add"
  }, {
    path: "/account/insurance/add/mama",
    component: _2cc55b12,
    name: "account-insurance-add-mama"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/account/patient/:id",
    component: _96cead52,
    name: "account-patient-id"
  }, {
    path: "/analysis/checkout/:id",
    component: _72dd164a,
    name: "analysis-checkout-id"
  }, {
    path: "/event/analysis/:id?",
    component: _3bc4fe74,
    name: "event-analysis-id"
  }, {
    path: "/monitoring/remote-monitoring/:id?",
    component: _e5096c86,
    name: "monitoring-remote-monitoring-id"
  }, {
    path: "/referral/analysis/:id?",
    component: _00ec373f,
    name: "referral-analysis-id"
  }, {
    path: "/referral/consultations/:id?",
    component: _c3369da6,
    name: "referral-consultations-id"
  }, {
    path: "/account/patient/:id?/anketa",
    component: _0f33ca3f,
    name: "account-patient-id-anketa"
  }, {
    path: "/doctors/:slug",
    component: _79578df5,
    name: "doctors-slug"
  }, {
    path: "/medicine-directions/:id?",
    component: _52bef010,
    name: "medicine-directions-id"
  }, {
    path: "/moms-school/:id?",
    component: _595587ca,
    name: "moms-school-id"
  }, {
    path: "/monitoring/:id?",
    component: _4f332f46,
    name: "monitoring-id"
  }, {
    path: "/queue/:id?",
    component: _0873b942,
    name: "queue-id"
  }, {
    path: "/specializations/:slug?",
    component: _68542409,
    name: "specializations-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
