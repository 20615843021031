import amplitude from 'amplitude-js';

export default function({ $config }, inject) {
  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.init($config.APP_AMPLITUDE_API_KEY, null, {
    includeReferrer: true,
    includeUtm: true
  });

  inject('amplitude', amplitudeInstance);
}
